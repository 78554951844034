import React, { lazy, Suspense } from 'react';
import './App.css';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory, History, UnregisterCallback } from 'history';
import { Box, CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import HeaderComponent from './components/Header';
import FooterComponent from './components/Footer';
import ErrorHandlerComponent from './components/ErrorHandler';
import {
  ROUTE_CAPTURES,
  ROUTE_GROUPS,
  ROUTE_THANKYOU,
  ROUTE_NETWORK_ERROR,
  ROUTE_INVALID_LINK,
  ROUTE_OFFLINE_KYC,
} from './helpers/routes';
import IntegrationService from './helpers/integrations';
import { ReloadFlag } from './store/Reload/types';
import { AppState } from './store';
import reloadHandleAction from './store/Reload/actions';
import {
  SaveRedirectUriAction,
  SaveExitUriAction,
} from './store/RedirectUri/actions';
import {
  changeLanguage,
  getNavigationConfirmation,
  getQueryParamsByName,
} from './helpers';
import { ThemeConfig } from './store/ThemeConfig/types';
import theme from './theme';
import { FooterDetails } from './components/Footer/store/types';
import Toast from './components/Toast';
import { ToastData } from './store/Toast/types';
import { DataService } from './services/DataService';
import { ClearRedirectingOverlayAction } from './store/Error/actions';
import { PAGE_SEQ_CAPTURE } from './helpers/constants';
import { log, LogLevel } from './helpers/loggerWrapper';
import RedirectComponent from './components/LandingPage';
import ClickTracker from './helpers/ui-click-event';

const GroupComponent = lazy(() => import('./components/Group'));
const IndvCaptureComponent = lazy(() => import('./components/IndvCapture'));
const ThankYouComponent = lazy(() => import('./components/ThankYou'));
const DefaultRouteComponent = lazy(() => import('./components/DefaultRoute'));
const AadhaarKYCComponent = lazy(
  () => import('./components/AadhaarXMLScraping/AadhaarKYC'),
);
const PoorConnectionComponent = lazy(
  () => import('./components/PoorConnection'),
);
const InvalidLinkComponent = lazy(
  () => import('./components/InvalidLinkComponent'),
);

interface StateProps {
  reloadFlag: ReloadFlag;
  ThemeConfig: ThemeConfig;
  footerDetails: FooterDetails;
  toastData: ToastData;
  error: Error | null;
}

interface DispatchProps {
  reloadHandleAction: typeof reloadHandleAction;
  SaveRedirectUriAction: typeof SaveRedirectUriAction;
  SaveExitUriAction: typeof SaveExitUriAction;
  ClearRedirectingOverlayAction: typeof ClearRedirectingOverlayAction;
}

interface Props extends StateProps, DispatchProps {}

class App extends React.Component<Props> {
  historyListener: UnregisterCallback;
  history: History = createBrowserHistory({
    getUserConfirmation: getNavigationConfirmation,
  });

  constructor(props: Props) {
    super(props);
    this.historyListener = this.history.listen((location, action) => {
      if (location.state) {
        DataService.pushVisitedPageOnSessionChannel(
          location.state.pagePath,
          this.history,
        );
      }
      if (
        location &&
        location.state &&
        location.state.pagePath !== PAGE_SEQ_CAPTURE &&
        location.state.pageType !== PAGE_SEQ_CAPTURE
      ) {
        DataService.removeAllDocumentTimers();
      }
      if (action === 'POP' && this.props.error != null) {
        this.props.ClearRedirectingOverlayAction();
      }
    });
  }

  componentDidMount(): void {
    if (ClickTracker?.clickEventHandler) {
      document.addEventListener('click', ClickTracker.clickEventHandler);
    }
    const { reloadFlag, reloadHandleAction } = this.props;
    this.storeCaptureToken();
    changeLanguage('en');
    if (!reloadFlag.value) {
      reloadHandleAction({ value: true });
      this.storeRedirectUrl();
      this.storeExitUrl();
      let { pathname } = window.location;
      if (pathname === ROUTE_CAPTURES || pathname === ROUTE_OFFLINE_KYC) {
        pathname = ROUTE_CAPTURES;
      } else if (pathname === ROUTE_GROUPS) {
        pathname = ROUTE_GROUPS;
      }
      this.history.push({
        pathname,
        search: window.location.search,
      });
    }
  }

  componentWillUnmount(): void {
    this.historyListener && this.historyListener();
  }

  storeCaptureToken(): void {
    const captureToken = getQueryParamsByName(this.history, 't') || '';
    DataService.setCaptureToken(captureToken);
  }

  storeRedirectUrl(): void {
    const { SaveRedirectUriAction } = this.props;
    const redirectUrl = getQueryParamsByName(this.history, 'redirect_uri');
    const decodedURI = decodeURI(redirectUrl || '');
    SaveRedirectUriAction({ uri: decodedURI || '' });
    if (redirectUrl) {
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'LogRedirectURL',
        eventType: 'RedirectURL',
        eventName: redirectUrl,
        component: 'App',
        eventSource: 'storeRedirectUrl',
      });
    }
  }

  storeExitUrl(): void {
    const { SaveExitUriAction } = this.props;
    const exitUrl = getQueryParamsByName(this.history, 'exit_uri');
    const decodedURI = decodeURI(exitUrl || '');
    SaveExitUriAction({ exit_uri: decodedURI || '' });
    if (exitUrl) {
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'LogExitURL',
        eventType: 'ExitURL',
        eventName: exitUrl,
        component: 'App',
        eventSource: 'storeExitUrl',
      });
    }
  }

  render(): JSX.Element {
    // we are taking this approach since passing it as Props directly to ErrorHandler has problems
    // and this is the only way to pass the history prop
    // https://stackoverflow.com/questions/48240449/type-is-not-assignable-to-type-intrinsicattributes-intrinsicclassattribu from here found this solution
    const history = { history: this.history };
    const { footerDetails, toastData } = this.props;
    return (
      <div className="App">
        <MuiThemeProvider theme={theme()}>
          <CssBaseline />
          <Box
            component="div"
            className="Maincontainer"
            style={{
              backgroundColor: footerDetails.backgroundColor,
              color: theme().palette.secondary.contrastText,
            }}
          >
            <ErrorHandlerComponent {...history}>
              <>
                <div className="Header">
                  <HeaderComponent history={this.history} />
                </div>
                {toastData.show && <Toast toastInfo={toastData} />}
                <Router history={this.history}>
                  <Suspense fallback={<RedirectComponent />}>
                    <Switch>
                      <Route
                        exact
                        path={ROUTE_GROUPS}
                        component={GroupComponent}
                      />
                      <Route
                        exact
                        path={ROUTE_CAPTURES}
                        component={IndvCaptureComponent}
                      />
                      <Route
                        exact
                        path={ROUTE_THANKYOU}
                        component={ThankYouComponent}
                      />
                      <Route
                        exact
                        path={ROUTE_NETWORK_ERROR}
                        component={PoorConnectionComponent}
                      />
                      <Route
                        exact
                        path={ROUTE_INVALID_LINK}
                        component={InvalidLinkComponent}
                      />
                      <Route
                        exact
                        path={ROUTE_OFFLINE_KYC}
                        render={(props): JSX.Element => (
                          <AadhaarKYCComponent
                            onAadhaarXMLDownloaded={
                              IntegrationService.AadhaarXMLDataBridge
                            }
                            {...props}
                          />
                        )}
                      />
                      <Route exact path="*" component={DefaultRouteComponent} />
                    </Switch>
                  </Suspense>
                </Router>
              </>
            </ErrorHandlerComponent>
            <FooterComponent />
          </Box>
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  reloadFlag: state.ReloadFlag,
  ThemeConfig: state.ThemeConfig,
  footerDetails: state.FooterDetails,
  toastData: state.ToastData,
  error: state.ErrorAndOverlay.error,
});

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  {
    reloadHandleAction,
    SaveRedirectUriAction,
    SaveExitUriAction,
    ClearRedirectingOverlayAction,
  },
)(App);
