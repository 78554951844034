import * as Sentry from '@sentry/react';
import { spanToJSON } from '@sentry/core';
import { Breadcrumb, SpanJSON } from '@sentry/types';

import { APP_ENV, SENTRY_DSN, LOGGER_URL } from '../helpers/envconfig';

import { APP_VERSION } from './constants';

const allowedEnvs = ['staging', 'uat', 'prod'];

const isUrlToSkip = (url?: string) => url?.includes?.(LOGGER_URL);

const beforeBreadcrumb = (breadcrumb: Breadcrumb) => {
  // Exclude all js-log publish
  if (breadcrumb?.category === 'xhr' && isUrlToSkip(breadcrumb?.data?.url)) {
    return null;
  }
  return breadcrumb;
};

const filterSpans = (spans: Sentry.Span[] = []): Sentry.Span[] => {
  const filteredSpans: Sentry.Span[] = [];
  for (const span of spans) {
    const spanJSON: Partial<SpanJSON> = spanToJSON(span);
    const spanData = spanJSON.data ?? {};
    if (
      spanData?.['sentry.op'] !== 'resource.script' && // Allow all other than static resource load
      !(spanData?.['sentry.op'] === 'http.client' && isUrlToSkip(spanData?.url)) // Exclude all js-log publish
    ) {
      filteredSpans.push(span);
    }
  }
  return filteredSpans;
};

export default class RealUserMonitoring {
  static init(token: string): void {
    if (
      window?.location?.hostname !== 'localhost' &&
      window?.location?.hostname !== '127.0.0.1' &&
      SENTRY_DSN &&
      allowedEnvs.includes(APP_ENV)
    ) {
      Sentry.setUser({ id: token });
      // Initialize Sentry here with your DSN or configuration
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: APP_ENV,
        integrations: [Sentry.browserTracingIntegration()],
        release: APP_VERSION,
        // Error Events Monitoring
        sampleRate: 1, //  Capture 100% of the errors (Default 1)
        // Performance Monitoring
        tracesSampleRate: 0.01, //  Capture 1% of the transactions
        // Session Replay
        replaysSessionSampleRate: 1.0, // This is set to 100% as session reply is conditional.
        replaysOnErrorSampleRate: 1.0, // Sampling 100% sessions where errors occur.
        beforeBreadcrumb,
        beforeSendTransaction(event) {
          const spans = event?.spans ?? [];
          event.spans = filterSpans(spans);
          return event;
        },
      });
    }
  }

  static lazyLoadReplay(): void {
    import('@sentry/browser').then((lazyLoadedSentry) => {
      Sentry.addIntegration(lazyLoadedSentry.replayIntegration());
    });
  }

  static setTags(tags: { [key: string]: string }): void {
    Sentry.setTags(tags);
  }

  static logError(error: any): void {
    Sentry.captureException(error);
  }

  static logMessage(
    message: string,
    level: 'info' | 'warning' | 'error' | 'fatal' = 'info',
    extra?: any,
  ): void {
    Sentry.captureMessage(message, { level, extra });
  }
}
