import { createInstance } from 'js-logger';
import { getUserAgent } from '.';
import { DataService } from '../services/DataService';
import { ENABLE_LOGGING } from './envconfig';
import { APP_VERSION, DEFAULT_LOG_VERSION } from './constants';
import RealUserMonitoring from './real-user-monitoring';

interface LogDetails {
  serviceCategory: string;
  service: string;
  eventType: string;
  eventName: string;
  component: string;
  eventSource: string;
  exceptionName?: string;
  exceptionDescription?: string;
  referenceID?: string;
  referenceType?: string;
  logVersion?: string;
  liveMonitoring?: boolean;
  publishToDLK?: boolean;
}

export enum LogLevel {
  Info = 'info',
  Error = 'error',
  Warning = 'warn',
}

interface HashMapOfPoints {
  // eslint-disable-next-line
  [key: string]: any;
}

export const loggers: HashMapOfPoints = {};

// TODO: need to add 6th param meta
export function createLogger(
  key: string,
  token: string,
  url: string,
  // eslint-disable-next-line
  conf?: any,
  // eslint-disable-next-line
  defaults?: any,
): void {
  try {
    if (token) {
      const loggerInstance = createInstance(
        token,
        'token',
        url,
        conf,
        defaults,
      );

      loggerInstance.setDefaults = {
        serviceCategory: 'Capture',
        applicationVersion: APP_VERSION,
        logVersion: DEFAULT_LOG_VERSION,
      };
      loggerInstance.setConf = {
        logUserAgentDefault: false,
        minPublishLevel: LogLevel.Error,
      };
      loggerInstance.setMeta = { capture_token: token };
      loggers[key] = loggerInstance;
    }
  } catch (error) {
    RealUserMonitoring.logError(error);
  }
}

export function log(
  logLevel: LogLevel | string,
  logDetails: LogDetails,
  // eslint-disable-next-line
  meta?: any,
  // eslint-disable-next-line
  options?: any,
): void {
  try {
    if (!loggers.capture || !ENABLE_LOGGING) return;
    if (logLevel === LogLevel.Info) {
      // eslint-disable-next-line
      delete logDetails.exceptionName;
      // eslint-disable-next-line
      delete logDetails.exceptionDescription;
    }
    if (!logDetails.referenceID) {
      logDetails.referenceID =
        DataService.getAvTaskId() || DataService.getCapturePublicId();
      logDetails.referenceType = DataService.getAvTaskId()
        ? 'AV.TaskID'
        : 'CaptureID';
    }
    loggers.capture.log(logLevel, logDetails, meta, options);
  } catch (error) {
    RealUserMonitoring.logError(error);
  }
}

export function init(
  token: string,
  url: string,
  // eslint-disable-next-line
  conf?: any,
  // eslint-disable-next-line
  defaults?: any,
): void {
  if (token) {
    // Setup logger only if we have non-blank token, otherwise the createInstance call fails
    if (!loggers.capture) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      createLogger('capture', token, url, conf, defaults);
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'LogUserAgent',
        eventName: getUserAgent(),
        eventType: 'Profile.UA',
        eventSource: 'init',
        component: 'loggerWrapper',
      });
    } else {
      throw new Error('Init cannot be invoked multiple times!');
    }
  }
}

export function setMetaDefaults(
  // eslint-disable-next-line
  meta: any,
  key?: string,
): void {
  const instanceKey = key || 'capture';
  if (!loggers[instanceKey]) return;
  if (meta) {
    loggers[instanceKey].setMeta = meta;
  }
}

export function setDefaults(
  // eslint-disable-next-line
  defaults: any,
  key?: string,
): void {
  const instanceKey = key || 'capture';
  if (!loggers[instanceKey]) return;
  if (defaults) {
    loggers[instanceKey].setDefaults = defaults;
  }
}

export function logPageVisit(
  pageName: string,
  pageComponent: string,
  source?: string,
  ignorePageIndex?: boolean,
  ignorePageInfo?: boolean,
  details?: object,
): void {
  if (DataService.getCaptureSessionId()) {
    const pageInfo = ignorePageInfo ? {} : DataService.getGenericPageInfo();
    const deniedPermissions = details ? details : {};
    const deniedPermissionsList = Array.isArray(details)
      ? details.map((item) => item.label)
      : [];
    let pageSequenceIndex = DataService.getCurrentPageIndex();
    let profileTag = DataService.getProfileTag();
    let queueName = DataService.getQueueName();
    let queueLabel = DataService.getQueueLabel();
    let packageId = DataService.getPackageId();
    if (pageName === 'ThankYou') {
      pageSequenceIndex = pageSequenceIndex ? pageSequenceIndex + 1 : null;
    }
    log(
      LogLevel.Info,
      {
        serviceCategory: 'Capture',
        service: 'Routing',
        eventType: 'Landed',
        eventName: pageName,
        component: pageComponent,
        eventSource: source || 'componentDidMount',
        logVersion: 'v2',
        publishToDLK: true,
      },
      {
        ...pageInfo,
        ...deniedPermissions,
        deniedPermissionsList,
        page_sequence_index: ignorePageIndex ? null : pageSequenceIndex,
        profileTag,
        queueName,
        queueLabel,
        packageId,
      },
    );
  }
}
