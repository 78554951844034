import { Middleware, AnyAction, MiddlewareAPI } from 'redux';
import { Dispatch } from 'react';
import RealUserMonitoring from '../helpers/real-user-monitoring';

const ErrorReportingMiddleware: Middleware =
  (api: MiddlewareAPI) =>
  (
    next: Dispatch<AnyAction>,
    // eslint-disable-next-line
) => (action: AnyAction): void => {
    const previousState = api.getState();
    const nextAction = next(action);
    const state = api.getState();
    try {
      return next(action);
    } catch (err) {
      RealUserMonitoring.logError(err);
      RealUserMonitoring.logMessage('Exception occurred', 'error', {
        previousState,
        nextAction,
        state,
      });
    }
  };

export default ErrorReportingMiddleware;
