import React from 'react';
import { getTatStartRef } from '../../helpers';
import { logPageVisit } from '../../helpers/loggerWrapper';
import SimpleOverlay from '../SimpleOverlay';
import i18n from '../../i18n/i18n';
import ClickTracker from '../../helpers/ui-click-event';
import { DataService } from '../../services/DataService';
import { ERROR_TYPE_INVALID_LINK } from '../../helpers/constants';
import RealUserMonitoring from '../../helpers/real-user-monitoring';

const translate = i18n.t.bind(i18n);
/**
 * Component to display Invalid Link Page
 */
export default class InvalidLinkComponent extends React.Component {
  loggerStartTime = getTatStartRef();

  componentDidMount(): void {
    ClickTracker?.setComponentName?.('InvalidLinkComponent');
    RealUserMonitoring.logMessage('Redirected to invalid link', 'info', {
      message: 'Invalid link',
    });
    logPageVisit('InvalidLink', 'InvalidLinkComponent');
    DataService.sendErrorEncountered({
      error_encountered: ERROR_TYPE_INVALID_LINK,
    });
  }

  componentWillUnmount(): void {
    ClickTracker?.unsetComponentName?.();
  }

  render(): JSX.Element {
    return (
      <SimpleOverlay
        showHeader
        titleConfig={{
          primaryText: translate('INVALID_LINK'),
          secondaryText: translate('INVALID_LINK_MESSAGE_1'),
          tertiaryText: translate('INVALID_LINK_MESSAGE_2'),
          titleSvg: 'invalid',
        }}
        showCrossButton={false}
      />
    );
  }
}
