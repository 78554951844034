//@ts-ignore
const windowEnv = window._env_;

const envObj = {
  APP_ENV: windowEnv.REACT_APP_APP_ENV,
  MOCK_ALL: windowEnv.REACT_APP_MOCK_ALL,
  MOCK_SOCKS: windowEnv.REACT_APP_MOCK_SOCKS,
  MOCK_API: windowEnv.REACT_APP_MOCK_API,
  MOCK_SUBMIT: windowEnv.REACT_APP_MOCK_SUBMIT,
  CAPTURE_SOCKET_URI: windowEnv.REACT_APP_CAPTURE_SOCKET_URI,
  VKYC_SOCKET_URI: windowEnv.REACT_APP_VKYC_SOCKET_URI,
  API_SERVER_URI: windowEnv.REACT_APP_API_SERVER_URI,
  LOGGER_URL: windowEnv.REACT_APP_LOGGER_URL,
  ENABLE_LOGGING: windowEnv.REACT_APP_ENABLE_LOGGING === 'true',
  MOCK_OFFLINE_KYC_API: windowEnv.REACT_APP_MOCK_OFFLINE_KYC_API,
  MEDIA_SERVER_URI: windowEnv.REACT_APP_MEDIA_SERVER_URI,
  AADHAAR_XML_SCRAPING_API: windowEnv.REACT_APP_AADHAAR_XML_SCRAPING_API,
  SV_MEDIA_CONSTRAINTS: windowEnv.REACT_APP_SV_MEDIA_CONSTRAINTS,
  AV_MEDIA_CONSTRAINTS: windowEnv.REACT_APP_AV_MEDIA_CONSTRAINTS,
  HIGH_RES_AV_MEDIA_CONSTRAINTS:
    windowEnv.REACT_APP_HIGH_RES_AV_MEDIA_CONSTRAINTS,
  SCHEDULING_URI: windowEnv.REACT_APP_SCHEDULING_BASE_URI,
  PRIMARY_VIDEO: windowEnv.REACT_APP_PRIMARY_VIDEO,
  RECONNECT_ON_SWITCH_ERROR: windowEnv.REACT_APP_RECONNECT_ON_SWITCH_ERROR,
  TEST_MEDIA_SERVER:
    windowEnv.REACT_APP_APP_ENV === 'staging'
      ? windowEnv.REACT_APP_TEST_MEDIA_SERVER
      : 'false',
  SCREEN_AWAKE_VIDEO_URI: windowEnv.REACT_APP_SCREEN_AWAKE_VIDEO_URI,
  STORAGE_AUTH_SERVICE: windowEnv.REACT_APP_STORAGE_AUTH_SERVICE,
  STORAGE_AUTH_TYPE: windowEnv.REACT_APP_STORAGE_AUTH_TYPE,
  ENABLE_PLAY_AFTER_PUBLISH:
    windowEnv.REACT_APP_ENABLE_PLAY_AFTER_PUBLISH === 'true',
  SELF_VIDEO_SERVER_BASE_URI: windowEnv.REACT_APP_SELF_VIDEO_SERVICE_BASE_URI,
  SELF_VIDEO_SSE_BASE_URI: windowEnv.REACT_APP_SELF_VIDEO_SSE_BASE_URI,
  SOCKET_RECONNECT_TIMEOUT: windowEnv.REACT_APP_SOCKET_RECONNECT_TIMEOUT
    ? parseInt(windowEnv.REACT_APP_SOCKET_RECONNECT_TIMEOUT, 10)
    : 30000,
  NETWORK_CHECK_SERVICE_BASE_URI:
    windowEnv.REACT_APP_NETWORK_CHECK_SERVICE_BASE_URI,
  VKYC_BACKEND_BASE_URI: windowEnv.REACT_APP_VKYC_BACKEND_BASE_URI,
  ALLOWED_REDIRECTS: windowEnv.REACT_APP_ALLOWED_REDIRECTS,
  USE_IMAGE_CAPTURE_API_DURING_VIDEO_CALL:
    windowEnv.REACT_APP_USE_IMAGE_CAPTURE_API_DURING_VIDEO_CALL === 'true',
  LOWEST_DOWNSCALE_BITRATE: windowEnv.REACT_APP_LOWEST_DOWNSCALE_BITRATE
    ? parseInt(windowEnv.REACT_APP_LOWEST_DOWNSCALE_BITRATE, 10)
    : 0,
  ENABLE_VARIABLE_BITRATE:
    windowEnv.REACT_APP_ENABLE_VARIABLE_BITRATE === 'true',
  ADVANCED_HEALTH_CHECK_ENABLED:
    windowEnv.REACT_APP_ADVANCED_HEALTH_CHECK_ENABLED === 'true',
  ADVANCED_HEALTH_CHECK_TIMER_MS:
    windowEnv.REACT_APP_ADVANCED_HEALTH_CHECK_TIMER_MS
      ? parseInt(windowEnv.REACT_APP_ADVANCED_HEALTH_CHECK_TIMER_MS, 10) || 5000
      : 5000,
  ADVANCED_HEALTH_CHECK_WEBRTC_STAT_INTERVAL:
    windowEnv.REACT_APP_ADVANCED_HEALTH_CHECK_WEBRTC_STAT_INTERVAL
      ? parseInt(
          windowEnv.REACT_APP_ADVANCED_HEALTH_CHECK_WEBRTC_STAT_INTERVAL,
          10,
        )
      : 500,
  APPEND_AUTH_PARAMS: windowEnv.REACT_APP_APPEND_AUTH_PARAMS === 'true',
  NO_LIMIT_FPS: windowEnv.REACT_APP_NO_LIMIT_FPS === 'true',
  WEBRTC_STAT_INTERVAL: windowEnv.REACT_APP_WEBRTC_STAT_INTERVAL
    ? parseInt(windowEnv.REACT_APP_WEBRTC_STAT_INTERVAL, 10) || 5000
    : 5000,
  AUDIO_STATS_INTERVAL: windowEnv.REACT_APP_AUDIO_STATS_INTERVAL
    ? parseInt(windowEnv.REACT_APP_AUDIO_STATS_INTERVAL, 10) || 20
    : 20,
  SWITCH_CAMERA_TIMER: windowEnv.REACT_APP_CAMERA_SWITCH_TIMER
    ? parseInt(windowEnv.REACT_APP_CAMERA_SWITCH_TIMER, 10)
    : 15000,
  RECONNECT_CALL_INTERVAL_ON_BAD_NETWORK: windowEnv.REACT_APP_RECONNECT_CALL_INTERVAL_ON_BAD_NETWORK
    ? parseInt(windowEnv.REACT_APP_RECONNECT_CALL_INTERVAL_ON_BAD_NETWORK, 10)
    : 10000,
  SENTRY_DSN: windowEnv.REACT_APP_SENTRY_DSN || '',
  FORCE_TURN_SERVER_ONLY: windowEnv.REACT_APP_FORCE_TURN_SERVER_ONLY || false
};

Object.freeze(envObj);

export const {
  APP_ENV,
  MOCK_ALL,
  MOCK_SOCKS,
  MOCK_API,
  MOCK_SUBMIT,
  CAPTURE_SOCKET_URI,
  VKYC_SOCKET_URI,
  API_SERVER_URI,
  LOGGER_URL,
  ENABLE_LOGGING,
  MOCK_OFFLINE_KYC_API,
  MEDIA_SERVER_URI,
  AADHAAR_XML_SCRAPING_API,
  SV_MEDIA_CONSTRAINTS,
  AV_MEDIA_CONSTRAINTS,
  HIGH_RES_AV_MEDIA_CONSTRAINTS,
  SCHEDULING_URI,
  PRIMARY_VIDEO,
  RECONNECT_ON_SWITCH_ERROR,
  TEST_MEDIA_SERVER,
  SCREEN_AWAKE_VIDEO_URI,
  STORAGE_AUTH_SERVICE,
  STORAGE_AUTH_TYPE,
  ENABLE_PLAY_AFTER_PUBLISH,
  SELF_VIDEO_SERVER_BASE_URI,
  SELF_VIDEO_SSE_BASE_URI,
  SOCKET_RECONNECT_TIMEOUT,
  NETWORK_CHECK_SERVICE_BASE_URI,
  VKYC_BACKEND_BASE_URI,
  ALLOWED_REDIRECTS,
  USE_IMAGE_CAPTURE_API_DURING_VIDEO_CALL,
  LOWEST_DOWNSCALE_BITRATE,
  ENABLE_VARIABLE_BITRATE,
  ADVANCED_HEALTH_CHECK_ENABLED,
  ADVANCED_HEALTH_CHECK_TIMER_MS,
  ADVANCED_HEALTH_CHECK_WEBRTC_STAT_INTERVAL,
  APPEND_AUTH_PARAMS,
  NO_LIMIT_FPS,
  WEBRTC_STAT_INTERVAL,
  AUDIO_STATS_INTERVAL,
  SWITCH_CAMERA_TIMER,
  SENTRY_DSN,
  FORCE_TURN_SERVER_ONLY,
  RECONNECT_CALL_INTERVAL_ON_BAD_NETWORK
} = envObj;
