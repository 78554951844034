import { AxiosResponse } from 'axios';
import { BehaviorSubject } from 'rxjs';
import * as axios from '../helpers/axios';
import MockOfflineKYCDataService from './MockOfflineKYCDataService';
import { DataService } from './DataService';
import { AADHAAR_XML_SCRAPING_API } from '../helpers/envconfig';
import { log, LogLevel } from '../helpers/loggerWrapper';
import i18n from '../i18n/i18n';
import RealUserMonitoring from '../helpers/real-user-monitoring';

const translate = i18n.t.bind(i18n);

export interface SourceDownState {
  isDown: boolean;
  message?: string;
}

export class OfflineKYCDataService {
  private static getCaptchaURL = `${AADHAAR_XML_SCRAPING_API}/_getCaptcha`;

  private static getOTPURL = `${AADHAAR_XML_SCRAPING_API}/_requestForOtp`;

  private static getXMLZipURL = `${AADHAAR_XML_SCRAPING_API}/_requestForXMLZip`;

  private static errorSource = 'OfflineKYCDataService';

  private static mockServiceBehaviour = false;

  private static authTokenKey: string | null = '';

  private static authTokenValue: string | null = '';

  private static timeoutValue = 30000;

  private static timeoutMessage = 'Timeout';

  public static UidaiState = new BehaviorSubject({
    isDown: false,
    message: '',
  });

  static SetServiceBehaviour(mockServiceBehaviour: boolean): void {
    OfflineKYCDataService.mockServiceBehaviour = mockServiceBehaviour;
  }

  static getParams(): { [key: string]: string | null } {
    if (OfflineKYCDataService.authTokenKey) {
      const params: { [key: string]: string | null } = {};
      params[OfflineKYCDataService.authTokenKey] =
        OfflineKYCDataService.authTokenValue;
      return params;
    }
    return {};
  }

  static SetAuthTokenKeyAndValue(
    authTokenKey: string | null,
    authTokenValue: string | null,
  ): void {
    OfflineKYCDataService.authTokenKey = authTokenKey;
    OfflineKYCDataService.authTokenValue = authTokenValue;
  }

  static GetCaptcha = async (): Promise<AxiosResponse | undefined> => {
    let response;
    if (OfflineKYCDataService.mockServiceBehaviour) {
      return MockOfflineKYCDataService.GetCaptcha();
    }
    try {
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'GetCaptcha',
        eventType: '',
        eventName: 'Fetch',
        component: OfflineKYCDataService.errorSource,
        eventSource: 'GetCaptcha',
      });
      response = await axios.get(OfflineKYCDataService.getCaptchaURL, {
        params: OfflineKYCDataService.getParams(),
        withCredentials: true,
        timeout: OfflineKYCDataService.timeoutValue,
      });
      if (response && response.status !== 200) {
        OfflineKYCDataService.UidaiState.next({
          isDown: true,
          message: response.data.Message,
        });
        log(
          LogLevel.Error,
          {
            serviceCategory: 'AadhaarXMLScraping',
            service: 'VerifyCaptcha',
            eventType: 'Exception',
            eventName: 'UIDAI_ERROR',
            component: OfflineKYCDataService.errorSource,
            eventSource: 'GetCaptcha',
            exceptionName: 'FailedToVerifyCaptcha',
          },
          {
            response,
          },
        );
        RealUserMonitoring.logError(response);
        RealUserMonitoring.logMessage('Exception', 'error', {
          response,
        });
      }
    } catch (error: any) {
      if (
        error.message.includes('timeout') &&
        error.message.includes('exceeded')
      ) {
        response = {
          data: {
            Message: translate('UIDAI_SERVER_ERROR_MESSAGE'),
            IsSuccess: false,
          },
          status: 500,
          statusText: OfflineKYCDataService.timeoutMessage,
          headers: {},
          config: {},
        };
      } else {
        DataService.RaiseError(new Error('Error While Fetching Captcha'), {
          errorServiceCategory: 'Capture',
          errorService: 'GetCaptcha',
          eventType: 'Exception',
          exceptionName: '',
          exceptionDescription: error,
          component: OfflineKYCDataService.errorSource,
          errorSource: 'GetCaptcha',
          metaData: {
            error,
          },
        });
        return Promise.reject();
      }
    }
    return response;
  };

  static GetOTP = async (
    id: string,
    captcha: string,
    imageFileName: string,
  ): Promise<AxiosResponse | undefined> => {
    if (OfflineKYCDataService.mockServiceBehaviour) {
      return MockOfflineKYCDataService.GetOTP(id, captcha);
    }
    let response;
    const data = {
      uidno: id,
      security_code: captcha,
      imageFileName,
    };
    try {
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'RequestOTP',
        eventType: '',
        eventName: 'Fetch',
        component: OfflineKYCDataService.errorSource,
        eventSource: 'GetOTP',
      });
      response = await axios.post(
        OfflineKYCDataService.getOTPURL,
        JSON.stringify(data),
        {
          params: OfflineKYCDataService.getParams(),
          withCredentials: true,
          timeout: OfflineKYCDataService.timeoutValue,
        },
      );
      if (response && response.status !== 200) {
        OfflineKYCDataService.UidaiState.next({
          isDown: true,
          message: response.data.Message,
        });
        log(
          LogLevel.Error,
          {
            serviceCategory: 'AadhaarXMLScraping',
            service: 'VerifyOTP',
            eventType: 'Exception',
            eventName: 'UIDAI_ERROR',
            component: OfflineKYCDataService.errorSource,
            eventSource: 'GetOTP',
            exceptionName: 'FailedToVerifyOTP',
          },
          {
            response,
          },
        );
        RealUserMonitoring.logError(response);
        RealUserMonitoring.logMessage('Exception', 'error', {
          response,
        });
      }
    } catch (error: any) {
      if (
        error.message.includes('timeout') &&
        error.message.includes('exceeded')
      ) {
        response = {
          data: {
            Message: translate('UIDAI_SERVER_ERROR_MESSAGE'),
            IsSuccess: false,
          },
          status: 500,
          statusText: OfflineKYCDataService.timeoutMessage,
          headers: {},
          config: {},
        };
      } else {
        DataService.RaiseError(new Error('Error While Getting OTP'), {
          errorServiceCategory: 'Capture',
          errorService: 'RequestOTP',
          eventType: 'Exception',
          exceptionName: '',
          exceptionDescription: error,
          component: OfflineKYCDataService.errorSource,
          errorSource: 'GetOTP',
          metaData: {
            error,
          },
        });
        return error;
      }
    }
    return response;
  };

  static GetXML = async (
    otp: string,
    shareCode: string,
  ): Promise<AxiosResponse | undefined> => {
    if (OfflineKYCDataService.mockServiceBehaviour) {
      return MockOfflineKYCDataService.GetXML(otp);
    }
    let response;
    const body = {
      totp: otp,
      zipcode: shareCode,
    };
    try {
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'GetXML',
        eventType: '',
        eventName: 'Fetch',
        component: OfflineKYCDataService.errorSource,
        eventSource: 'GetXML',
      });
      response = await axios.post(
        OfflineKYCDataService.getXMLZipURL,
        JSON.stringify(body),
        {
          params: OfflineKYCDataService.getParams(),
          withCredentials: true,
          headers: {
            Accept: 'application/json',
          },
          responseType: 'arraybuffer',
          timeout: OfflineKYCDataService.timeoutValue,
        },
      );
      if (response && response.headers['content-type'] === 'application/json') {
        const dataView = new DataView(response.data);
        const decoder = new TextDecoder('utf8');
        const resp = JSON.parse(decoder.decode(dataView));
        response.data = resp;
        if (resp && resp.IsUidaiDown) {
          OfflineKYCDataService.UidaiState.next({
            isDown: true,
            message: resp.Message,
          });
        }
      }
    } catch (error: any) {
      if (
        error.message.includes('timeout') &&
        error.message.includes('exceeded')
      ) {
        response = {
          data: {
            Message: translate('UIDAI_SERVER_ERROR_MESSAGE'),
            IsSuccess: false,
          },
          status: 500,
          statusText: OfflineKYCDataService.timeoutMessage,
          headers: {},
          config: {},
        };
      } else {
        DataService.RaiseError(new Error('Error While Fetching XML'), {
          errorServiceCategory: 'Capture',
          errorService: 'GetXML',
          eventType: 'Exception',
          exceptionName: '',
          exceptionDescription: error,
          component: OfflineKYCDataService.errorSource,
          errorSource: 'GetXML',
          metaData: {
            error,
          },
        });
        return error;
      }
    }
    return response;
  };
}
